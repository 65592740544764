import React, {Component} from "react"
import Menu from "./Menu"
import axios from 'axios'
import { Container } from "../components/Main"
import {Button, Form, Input} from "../components/Forms"
import {NoBottomMargin} from "../components/Utils"
import {Link} from "react-router-dom"
import {AuthContext} from "../context/auth";
import {NOTIFICATORAPI} from "../constants";
import {MENUITEMS} from "../StaticMenus";

class OpsgenieSettings extends Component {
    static contextType = AuthContext

    constructor(props){
        super(props)
        this.state={
            OpsgenieItems: [],
            token: "",
        }
        this.postOpsgenieSettings = this.postOpsgenieSettings.bind(this)
        this.deleteOpsgenieSetting = this.deleteOpsgenieSetting.bind(this)
        this.keyPressed = this.keyPressed.bind(this)
    }

    componentDidMount() {
        axios.get(
            NOTIFICATORAPI + "opsgenie/setting",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({ OpsgenieItems: response.data }))
    }

    deleteOpsgenieSetting(itemId){
        axios.delete(
            NOTIFICATORAPI + "opsgenie/setting/" + itemId,
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                axios.get(
                    NOTIFICATORAPI + "opsgenie/setting",
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).then(response => this.setState({ OpsgenieItems: response.data }))
            }
        }).catch(e => {
            console.log(e)
        })
    }

    postOpsgenieSettings() {
        axios.post(
            NOTIFICATORAPI + "opsgenie/setting",
            {
                token: this.state.token,
            },
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                axios.get(
                    NOTIFICATORAPI + "opsgenie/setting",
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).then(response => this.setState({ OpsgenieItems: response.data }))
            }
        }).catch(e => {
            console.log(e)
        })
    }

    keyPressed(event) {
        if (event.key === "Enter") {
            this.postOpsgenieSettings()
        }
    }

    render(){
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                <Container className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <Form>
                                <div className="form-group">
                                    <label>Token:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ token: e.target.value})
                                        }}
                                        value={this.state.token}
                                    />
                                </div>
                                <Button onClick={this.postOpsgenieSettings} className="btn btn-primary">Add</Button>
                            </Form>
                            <hr className="d-sm-none" />
                        </div>
                        <div className="col-sm-8">
                            <NoBottomMargin className="jumbotron text-center">
                                <h2>Listing</h2>
                                <table id="listing" className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Token</th>
                                        <th> </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.OpsgenieItems.map((item) => (
                                        <tr key={item.token}><td>{item.token}</td><td><Link to='/opsgenie' onClick={() => this.deleteOpsgenieSetting(item.id)}>delete</Link></td></tr>
                                    ))}
                                    </tbody>
                                </table>
                            </NoBottomMargin>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default OpsgenieSettings
