import React, {Component} from "react"
import Menu from "./Menu"
import axios from 'axios'
import { Container } from "../components/Main"
import {Button, Form, Input} from "../components/Forms"
import {NoBottomMargin} from "../components/Utils"
import {Link} from "react-router-dom"
import {AuthContext} from "../context/auth";
import {NOTIFICATORAPI} from "../constants";
import {MENUITEMS} from "../StaticMenus";
import { trackPromise } from 'react-promise-tracker';

class ChannelMappings extends Component {
    static contextType = AuthContext

    constructor(props){
        super(props)
        this.state={
            channelMaps: [],
            clickUpList: [],
            slackList: [],
            entityName: "",
            slackChannelId: "",
            mapType: "zabbix",
            whispererListName: "default",
            errorMessage: ""
        }
        this.postChannelMappings = this.postChannelMappings.bind(this)
        this.keyPressed = this.keyPressed.bind(this)
        this.mapTypeChanged = this.mapTypeChanged.bind(this)
        this.lookUpClickUpLists = this.lookUpClickUpLists.bind(this)
        this.getChannelMaps = this.getChannelMaps.bind(this)
        this.getSlackChannels = this.getSlackChannels.bind(this)
        this.deleteChannelMap = this.deleteChannelMap.bind(this)
        this.deleteChannelMapWithPrune = this.deleteChannelMapWithPrune.bind(this)
    }

    deleteChannelMap(itemId){
        trackPromise(
            axios.delete(
                NOTIFICATORAPI + "slack/map/" + itemId,
                {
                    headers: {
                        'x-user-token': this.context.authTokens
                    }
                }
            ).then(result => {
                this.getChannelMaps()
            }).catch(e => {
                console.log(e)
            })
        )
    }

    deleteChannelMapWithPrune(itemId){
        trackPromise(
            axios.delete(
                NOTIFICATORAPI + "slack/map/" + itemId + "?prune=true",
                {
                    headers: {
                        'x-user-token': this.context.authTokens
                    }
                }
            ).then(result => {
                this.getChannelMaps()
            }).catch(e => {
                console.log(e)
            })
        )
    }

    getSlackChannels(){
        trackPromise(
            axios.get(
                NOTIFICATORAPI + "slack/list",
                {
                    headers: {
                        'x-user-token': this.context.authTokens
                    }
                }
            ).then(response => this.setState(
                	{
                		slackList: [{slack_channel_id: "",slack_channel_name: "" }].concat(
                			response.data.sort((a,b) => (a.slack_channel_name > b.slack_channel_name) ? 1 : ((b.slack_channel_name > a.slack_channel_name) ? -1 : 0))
                		)
                	}
                )
            )
        )
    }

    getChannelMaps() {
        trackPromise(
            axios.get(
                NOTIFICATORAPI + "slack/map",
                {
                    headers: {
                        'x-user-token': this.context.authTokens
                    }
                }
            ).then(response => {
                    this.setState(
                    	{ channelMaps: response.data.sort(
                    		(a,b) => (a.channel_name > b.channel_name) ? 1 : ((b.channel_name > a.channel_name) ? -1 : 0)
                		) }
            		)
                    if (this.slackChannelId === "" && response.data.length){
                        this.slackChannelId = response.data[0].channel_id
                    }
                }
            )
        )
    }

    componentDidMount() {
        this.getChannelMaps()
        this.getSlackChannels()
    }

    postChannelMappings() {
        trackPromise(
            axios.post(
                NOTIFICATORAPI + this.state.mapType + "/slack/map",
                {
                    group_name: this.state.entityName,
                    channel_id: this.state.slackChannelId,
                },
                {
                    headers: {
                        'x-user-token': this.context.authTokens
                    }
                }
            ).then(result => {
                if (result.status === 200) {
                    console.log(result.data)
                    this.getChannelMaps()
                }
            }).catch(e => {
                console.log(e)
                this.setState({ errorMessage: "Channel not found, check availability of Slack" })
            })
        )
    }

    keyPressed(event) {
        if (event.key === "Enter") {
            this.postChannelMappings()
        }
    }

    lookUpClickUpLists(){
        trackPromise(
            axios.get(
                NOTIFICATORAPI + "clickup/list",
                {
                    headers: {
                        'x-user-token': this.context.authTokens
                    }
                }
            ).then(response => {
                    this.setState({ clickUpList: response.data.sort() })
                    this.setState({ whispererListName: "clickupLists" })
                }
            )
        )
    }

    mapTypeChanged(e){
        this.setState({ mapType: e.target.value})
        if (e.target.value === 'clickup'){
            this.lookUpClickUpLists()
        }
    }

    render(){
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                {this.state.errorMessage &&
                <div className="container">
                    <div className="alert alert-danger">
                        <strong>Error!</strong> {this.state.errorMessage}
                    </div>
                </div>
                }
                <Container className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <Form>
                                <datalist id="default">
                                    <option key="default" value="Choose something in map type" />
                                </datalist>
                                <datalist id="clickupLists">
                                    {this.state.clickUpList.map((item) =>
                                        <option key={item} value={item} />
                                    )}
                                </datalist>
                                <div className="form-group">
                                    <label>Select map typ:</label>
                                    <select
                                        className="form-control"
                                        onChange={e => {
                                            this.mapTypeChanged(e);
                                        }}
                                        value={this.state.mapType}
                                    >
                                        <option>zabbix</option>
                                        <option>twd</option>
                                        <option>opsgenie</option>
                                        <option>clickup</option>
                                        <option>freshservice</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>{this.state.mapType.charAt(0).toUpperCase() + this.state.mapType.slice(1)} Entity:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ entityName: e.target.value})
                                        }}
                                        value={this.state.typeName}
                                        list={this.state.whispererListName}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Slack Channel Name:</label>
                                    <select
                                        className="form-control"
                                        onChange={e => {
                                            this.setState({ slackChannelId: e.target.value})
                                        }}
                                        value={this.state.slackChannelId}
                                    >
                                        {this.state.slackList.map((item) =>
                                            <option key={item.slack_channel_id} value={item.slack_channel_id}>{item.slack_channel_name}</option>
                                        )}
                                    </select>
                                </div>
                                <Button onClick={this.postChannelMappings} className="btn btn-primary">Add</Button>
                            </Form>
                            <ul className="nav nav-pills flex-column">
                                <li className="nav-item"><p>delete: removes only the mapping</p></li>
                                <li className="nav-item"><p>delete with purge: removes the mapping and also removes every reference to the channel in the database</p></li>
                            </ul>
                            <hr className="d-sm-none" />
                        </div>
                        <div className="col-sm-8">
                            <NoBottomMargin className="jumbotron text-center">
                                <h2>Listing</h2>
                                <table id="listing" className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Group</th>
                                        <th>Slack Channel Name</th>
                                        <th>Mapping Type</th>
                                        <th> </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.channelMaps.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.group_name}</td>
                                            <td>{item.channel_name}</td>
                                            <td>{item.group_type}</td>
                                            <td>{item.key}</td>
                                            <td><Link to='/mappings' onClick={() => this.deleteChannelMap(item.id)}>delete</Link></td>
                                            <td><Link to='/mappings' onClick={() => this.deleteChannelMapWithPrune(item.id)}>delete with purge</Link></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </NoBottomMargin>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default ChannelMappings
