import React from "react";
import Menu from "./Menu";
import { Container } from "../components/Main";
import {MENUITEMS} from "../StaticMenus";

function Home(props) {
    return (
        <div>
            <Menu menu={MENUITEMS}/>
            <Container className="container">
                <div className="row">
                    <div className="col-sm-4">
                        <h2>About Notificator</h2>
                        <p>This is a small admin page to manage and see what Notificator has on you</p>
                        <h3>Some Links</h3>
                        <p>Project Navigation</p>
                        <ul className="nav nav-pills flex-column">
                            <li className="nav-item">
                                <a className="nav-link" href="https://gitlab.com/lukapo/notificator">gitlab.com</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://codecov.io/gl/lukapo/notificator">codecov.io</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://lukapo.cz/">lukapo.cz</a>
                            </li>
                        </ul>
                        <hr className="d-sm-none" />
                    </div>
                    <div className="col-sm-8">
                        <div className="fakeimg">
                            <img alt="kent-brockman" src="https://notificator-static.s3.eu-central-1.amazonaws.com/img/kent-brockman.jpg" />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Home;
