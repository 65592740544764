import React, {Component} from "react";
import {AuthContext} from "../context/auth";
import Menu from "./Menu";
import AnalyticsMenu from "../components/AnalyticsMenu";
import {ANALYTICSMENUITEMS, MENUITEMS} from "../StaticMenus";

class Analytics extends Component {
    static contextType = AuthContext

    render() {
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                <div className="container">
                    <div className="row">
                        <AnalyticsMenu menu={ANALYTICSMENUITEMS} />
                        <div className="col-sm-8">
                            <h2>Analytics</h2>
                            <p>This part of the Notificator servers for analyzing incoming alerts.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Analytics