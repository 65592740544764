import React from "react";
import { MainFooter } from "../components/Main";

function Footer(props) {
    return (
        <MainFooter className="jumbotron text-center">
            <p>From <a href="https://github.com/beranm14">beranm14</a> at <a href="https://lukapo.cz/">Lukapo</a></p>
        </MainFooter>
    );
}

export default Footer;
