import styled from 'styled-components';

const Container = styled.div`
    margin-top:30px
`;

const MainHeader = styled.div`
    margin-bottom:0
`;
const MainFooter = styled.div`
    margin-bottom:0
`;

export { Container, MainHeader, MainFooter };