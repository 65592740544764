import React, { Component }  from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom"
import PrivateRoute from './PrivateRoute'
import Home from './pages/Home'
import Login from "./pages/Login"
import { AuthContext } from "./context/auth"
import Header from "./pages/Header"
import Footer from "./pages/Footer"
import ZabbixSettings from "./pages/ZabbixSettings"
import TwdSettings from "./pages/TwdSettings"
import OpsgenieSettings from "./pages/OpsgenieSettings"
import Schedules from "./pages/Schedules"
import Cleanup from "./pages/Cleanup"
import ChannelMappings from "./pages/ChannelMappings"
import Policies from "./pages/Policies"
import Analytics from "./pages/Analytics"
import ShortAlertLookback from "./pages/ShortAlertLookback"
import ShortTicketLookback from "./pages/ShortTicketLookback"
import IngestedEvents from "./pages/IngestedEvents"
import ClickupSettings from "./pages/ClickupSettings"
import ClickupMonthExport from "./pages/ClickupMonthExport"
import ClickupWeekOverview from "./pages/ClickupWeekOverview"

class App extends Component {
  constructor(props){
    super(props)
    this.state={
      authTokens: JSON.parse(localStorage.getItem("tokens"))
    }
    this.setTokens = this.setTokens.bind(this)
  }
  setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data))
    this.setState({authTokens: data})
  }
  componentDidMount() {
    this.setState({authTokens: JSON.parse(localStorage.getItem("tokens"))})
  }
  render() {
    return (
        <AuthContext.Provider value={{ authTokens: this.state.authTokens, setAuthTokens: this.setTokens }}>
          <Router>
            <Header/>
            <PrivateRoute exact path="/" component={Home}/>
            <PrivateRoute path="/zabbix" component={ZabbixSettings}/>
            <PrivateRoute path="/twd" component={TwdSettings}/>
            <PrivateRoute path="/opsgenie" component={OpsgenieSettings}/>
            <PrivateRoute path="/clickup" component={ClickupSettings}/>
            <PrivateRoute path="/schedule" component={Schedules}/>
            <PrivateRoute path="/mappings" component={ChannelMappings}/>
            <PrivateRoute path="/cleanup" component={Cleanup}/>
            <PrivateRoute path="/policy" component={Policies}/>
            <PrivateRoute exact path="/analytics" component={Analytics}/>
            <PrivateRoute path="/analytics/alert_period_summary" component={ShortAlertLookback}/>
            <PrivateRoute path="/analytics/ticket_period_summary" component={ShortTicketLookback}/>
            <PrivateRoute path="/analytics/ingested_events" component={IngestedEvents}/>
            <PrivateRoute path="/analytics/clickup_month_export" component={ClickupMonthExport}/>
            <PrivateRoute path="/analytics/clickup_week_overview" component={ClickupWeekOverview}/>
            <Route path="/login" component={Login}/>
            <Footer/>
          </Router>
        </AuthContext.Provider>
    )
  }
}

export default App
