import React, {Component} from "react"
import Menu from "./Menu"
import axios from 'axios'
import { Container } from "../components/Main"
import {Button, Form, Input} from "../components/Forms"
import {NoBottomMargin} from "../components/Utils"
import {Link} from "react-router-dom"
import {AuthContext} from "../context/auth";
import {NOTIFICATORAPI} from "../constants";
import {MENUITEMS} from "../StaticMenus";

class Schedules extends Component {
    static contextType = AuthContext

    constructor(props){
        super(props)
        this.state={
            items: [],
            summary: "",
            cronline: "",
            actionType: "zabbix_events_summary",
            errorMessage: ''
        }
        this.postSchedule = this.postSchedule.bind(this)
        this.deleteSchedule = this.deleteSchedule.bind(this)
        this.keyPressed = this.keyPressed.bind(this)
    }

    componentDidMount() {
        axios.get(
            NOTIFICATORAPI + "schedule/setting",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({ items: response.data }))
    }

    deleteSchedule(itemId){
        axios.delete(
            NOTIFICATORAPI + "schedule/setting/" + itemId,
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                axios.get(
                    NOTIFICATORAPI + "schedule/setting",
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).then(response => this.setState({ items: response.data }))
            }
        }).catch(e => {
            console.log(e)
        })
    }

    executeSchedule(itemId){
        axios.post(
            NOTIFICATORAPI + "schedule/execute/" + itemId,
            {},
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
            } else {
                this.setState({errorMessage: "Execution of the schedule failed " + result.data.status})
            }
        }).catch(e => {
            this.setState({errorMessage: "Execution of the schedule failed"})
            console.log(e)
        })
    }

    postSchedule() {
        axios.post(
            NOTIFICATORAPI + "schedule/setting",
            {
                summary: this.state.summary,
                cronline: this.state.cronline,
                action_type: this.state.actionType
            },
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            this.setState({errorMessage: ''})
            if (result.status === 200) {
                console.log(result.data)
                axios.get(
                    NOTIFICATORAPI + "schedule/setting",
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).then(response => this.setState({ items: response.data }))
            }
        }).catch(e => {
            console.log(e)
            this.setState({errorMessage: "Format of the schedule posted is wrong, see cronline"})
        })
    }

    keyPressed(event) {
        if (event.key === "Enter") {
            this.postSchedule()
        }
    }

    render(){
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                {this.state.errorMessage &&
                    <div className="container">
                        <div className="alert alert-danger">
                            <strong>Error!</strong> {this.state.errorMessage}
                        </div>
                    </div>
                }
                <Container className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <Form>
                                <div className="form-group">
                                    <label>Summary:</label>
                                    <textarea
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ summary: e.target.value})
                                        }}
                                        value={this.state.summary}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Cronline:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ cronline: e.target.value})
                                        }}
                                        value={this.state.cronline}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Select type:</label>
                                    <select
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ actionType: e.target.value});
                                        }}
                                        value={this.state.actionType}
                                    >
                                        <option>zabbix_events_summary</option>
                                        <option>zabbix_check_opened_alerts</option>
                                        <option>zabbix_check_renamed_triggers</option>
                                        <option>zabbix_check_opened_events</option>
                                        <option>opsgenie_oncaller</option>
                                        <option>twd_check_active_tickets</option>
                                    </select>
                                </div>
                                <Button onClick={this.postSchedule} className="btn btn-primary">Add</Button>
                            </Form>
                            <hr className="d-sm-none" />
                            <p>There are few types of schedule actions you can create:</p>
                            <ul className="nav nav-pills flex-column">
                                <li className="nav-item">
                                    <tt>zabbix_events_summary</tt> -- that create a summary of opened alerts and
                                    sends them to each channel the Notificator is in
                                </li>
                                <li className="nav-item">
                                    <tt>zabbix_check_opened_events</tt> -- this usually shows nothing in channel, it
                                    should just check a Zabbix server if everything is in sync with the Notificator
                                    in case of opened events, if they get changed at Zabbix and Notificator does not
                                    notice, this does the trick
                                </li>
                                <li className="nav-item">
                                    <tt>zabbix_check_opened_alerts</tt> -- the same as above but instead it looks up
                                    the opened alerts and if they are not present at Notificator, they get open, it
                                    checks only one hour in the past
                                </li>
                                <li className="nav-item">
                                    <tt>zabbix_check_renamed_triggers</tt> -- the same as above but it checks if
                                    every information about the Zabbix event checks out at Notificator and if not,
                                    it will sync them
                                </li>
                                <li className="nav-item">
                                    <tt>opsgenie_oncaller</tt> -- if you have submitted OpsGenie API token to your
                                    account, you can enable Notificator to set emoji :redphone: emoji and status
                                    text could be set in summary
                                </li>
                                <li className="nav-item">
                                    <tt>twd_check_active_tickets</tt> -- this is sanity check for TWD, it calls all
                                    the ticket which are active and checks if they are still active, do not do this
                                    too often, only one ticket is checked in 5 secs anyway
                                </li>
                            </ul>
                            <br />
                        </div>
                        <div className="col-sm-8">
                            <NoBottomMargin className="jumbotron text-center">
                                <h2>Listing</h2>
                                <table id="listing" className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Summary</th>
                                        <th>Cronline</th>
                                        <th>Action type</th>
                                        <th> </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.items.filter(i => [
                                        "zabbix_events_summary",
                                        "zabbix_check_opened_events",
                                        "zabbix_check_opened_alerts",
                                        "zabbix_check_renamed_triggers",
                                        "opsgenie_oncaller",
                                        "twd_check_active_tickets",
                                    ].includes(i.action_type)).map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.summary}</td>
                                            <td>{item.cronline}</td>
                                            <td>{item.action_type}</td>
                                            <td><Link to='/schedule' onClick={() => this.executeSchedule(item.id)}>execute now</Link></td>
                                            <td><Link to='/schedule' onClick={() => this.deleteSchedule(item.id)}>delete</Link></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </NoBottomMargin>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default Schedules
