import React from "react"
import { usePromiseTracker } from "react-promise-tracker"
import { MainHeader } from "../components/Main"
import Loader from "react-loader-spinner"

function Header(props) {
    const { promiseInProgress } = usePromiseTracker()
    return (
        <MainHeader className="jumbotron text-center">

        { promiseInProgress && 
            <Loader type="ThreeDots" color="#343A40" height={100} width={100}/> }
        { promiseInProgress || 
            <h1>Notificator GUI</h1> }
            <p>Zabbix OpsGenie Slack integrator for DevOps people</p>
        </MainHeader>
    );
}

export default Header;
