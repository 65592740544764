import React, {Component} from "react"
import Menu from "./Menu"
import axios from 'axios'
import { Container } from "../components/Main"
import {Button, Form, Input} from "../components/Forms"
import {NoBottomMargin} from "../components/Utils"
import {Link} from "react-router-dom"
import {AuthContext} from "../context/auth";
import {NOTIFICATORAPI} from "../constants";
import {MENUITEMS} from "../StaticMenus";

class ClickupSettings extends Component {
    static contextType = AuthContext

    constructor(props){
        super(props)
        this.state={
            clickupItems: [],
            clickupClientId: "",
            clickupClientSecret: "",
            userDetail: {},
        }
        this.postClickupSettings = this.postClickupSettings.bind(this)
        this.deleteClickupSetting = this.deleteClickupSetting.bind(this)
        this.getUserDetail = this.getUserDetail.bind(this)
        this.keyPressed = this.keyPressed.bind(this)
    }


    getUserDetail() {
        axios.get(
            NOTIFICATORAPI + "user_detail",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({ userDetail: response.data }))
    }

    componentDidMount() {
        this.getUserDetail()
        axios.get(
            NOTIFICATORAPI + "clickup/setting",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({ clickupItems: response.data }))
    }

    deleteClickupSetting(itemId){
        axios.delete(
            NOTIFICATORAPI + "clickup/setting/" + itemId,
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                axios.get(
                    NOTIFICATORAPI + "clickup/setting",
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).then(response => this.setState({ clickupItems: response.data }))
            }
        }).catch(e => {
            console.log(e)
        })
    }

    postClickupSettings() {
        axios.post(
            NOTIFICATORAPI + "clickup/setting",
            {
                clickup_client_id: this.state.clickupClientId,
                clickup_client_secret: this.state.clickupClientSecret
            },
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                axios.get(
                    NOTIFICATORAPI + "clickup/setting",
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).then(response => this.setState({ clickupItems: response.data }))
            }
        }).catch(e => {
            console.log(e)
        })
    }

    keyPressed(event) {
        if (event.key === "Enter") {
            this.postClickupSettings()
        }
    }

    render(){
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                <Container className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            {this.state.clickupItems && this.state.clickupItems.length &&
                            <div className="container">
                                Use following url as Redirect URL: "api.notificator.io/clickup/register_code/{this.state.userDetail.uuid}"
                            </div>
                            }
                            <Form>
                                <div className="form-group">
                                    <label>Clickup Client Id:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ clickupClientId: e.target.value})
                                        }}
                                        value={this.state.endpoint}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Clickup Client Secret:</label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ clickupClientSecret: e.target.value})
                                        }}
                                        value={this.state.password}
                                    />
                                </div>
                                <Button onClick={this.postClickupSettings} className="btn btn-primary">Add</Button>
                            </Form>
                            <hr className="d-sm-none" />
                            {this.state.clickupItems && this.state.clickupItems.length &&
                                <div className="container">
                                    Once you setup the credentials, please, click on following link:
                                    <a href={"https://app.clickup.com/api?client_id=" + this.state.clickupItems[0].clickup_client_id + "&redirect_uri=api.notificator.io/clickup/register_code/" + this.state.userDetail.uuid}>here</a>
                                </div>
                            }
                        </div>
                        <div className="col-sm-8">
                            <NoBottomMargin className="jumbotron text-center">
                                <h2>Listing</h2>
                                <table id="listing" className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Client ID</th>
                                        <th>Client Secret</th>
                                        <th> </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.clickupItems.map((item) => (
                                        <tr key={item.id}><td>{item.clickup_client_id.substring(0, 10)}...</td><td>{item.clickup_client_secret.substring(0, 10)}...</td><td><Link to='/clickup' onClick={() => this.deleteClickupSetting(item.id)}>delete</Link></td></tr>
                                    ))}
                                    </tbody>
                                </table>
                            </NoBottomMargin>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default ClickupSettings
