import React, {Component} from "react"
import Menu from "./Menu"
import axios from 'axios'
import {NOTIFICATORAPI} from '../constants'
import { Container } from "../components/Main"
import {Button, Form, Input} from "../components/Forms"
import {NoBottomMargin} from "../components/Utils"
import {Link} from "react-router-dom"
import {AuthContext} from "../context/auth";
import {MENUITEMS} from "../StaticMenus";

class ZabbixSettings extends Component {
    static contextType = AuthContext

    constructor(props){
        super(props)
        this.state={
            zabbixItems: [],
            userName: "",
            password: "",
            endpoint: "",
        }
        this.postZabbixSettings = this.postZabbixSettings.bind(this)
        this.deleteZabbixSetting = this.deleteZabbixSetting.bind(this)
        this.keyPressed = this.keyPressed.bind(this)
    }

    componentDidMount() {
        axios.get(
            NOTIFICATORAPI + "zabbix/setting",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({ zabbixItems: response.data }))
    }

    deleteZabbixSetting(itemId){
        axios.delete(
            NOTIFICATORAPI + "zabbix/setting/" + itemId,
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                axios.get(
                    NOTIFICATORAPI + "zabbix/setting",
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).then(response => this.setState({ zabbixItems: response.data }))
            }
        }).catch(e => {
            console.log(e)
        })
    }
    
    postZabbixSettings() {
        axios.post(
            NOTIFICATORAPI + "zabbix/setting",
            {
                username: this.state.userName,
                password: this.state.password,
                endpoint: this.state.endpoint
            },
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                axios.get(
                    NOTIFICATORAPI + "zabbix/setting",
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).then(response => this.setState({ zabbixItems: response.data }))
            }
        }).catch(e => {
            console.log(e)
        })
    }

    keyPressed(event) {
        if (event.key === "Enter") {
            this.postZabbixSettings()
        }
    }

    render(){
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                <Container className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <Form>
                                <div className="form-group">
                                    <label>Endpoint:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ endpoint: e.target.value})
                                        }}
                                        value={this.state.endpoint}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password:</label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ password: e.target.value})
                                        }}
                                        value={this.state.password}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Username:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ userName: e.target.value})
                                        }}
                                        value={this.state.userName}
                                    />
                                </div>
                                <Button onClick={this.postZabbixSettings} className="btn btn-primary">Add</Button>
                            </Form>
                            <hr className="d-sm-none" />
                        </div>
                        <div className="col-sm-8">
                            <NoBottomMargin className="jumbotron text-center">
                                <h2>Listing</h2>
                                <table id="listing" className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Endpoint</th>
                                        <th>Username</th>
                                        <th> </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.zabbixItems.map((item) => (
                                        <tr key={item.username}><td>{item.username}</td><td>{item.endpoint}</td><td><Link to='/zabbix' onClick={() => this.deleteZabbixSetting(item.id)}>delete</Link></td></tr>
                                    ))}
                                    </tbody>
                                </table>
                            </NoBottomMargin>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default ZabbixSettings
