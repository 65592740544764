import {Component} from "react";
import axios from "axios";
import {NOTIFICATORAPI} from "../../constants";


class AnalyticsComponent extends Component {

    getSlackChannels(){
        axios.get(
            NOTIFICATORAPI + "slack/list",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(
            response => this.setState(
                {
                    slackList: response.data.sort((a,b) => (a.slack_channel_name > b.slack_channel_name) ? 1 : ((b.slack_channel_name > a.slack_channel_name) ? -1 : 0))
                }
            )
        )
    }
}

export default AnalyticsComponent
