import React from 'react'
import {NavLink} from "react-router-dom";

const Menu = ({ menu }) => {
    return (
        <ul className="navbar-nav">
        {menu.map((menuItem) => (
            <li key={menuItem.path} className="nav-item">
                <NavLink className="nav-link" exact={true} activeclassname='nav-link active' to={menuItem.path} key={menuItem.path}>{menuItem.name}</NavLink>
            </li>
        ))}
        </ul>
    )
};

export default Menu;
