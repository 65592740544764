import React, {Component} from "react"
import Menu from "./Menu"
import axios from 'axios'
import { Container } from "../components/Main"
import {Button, Form} from "../components/Forms"
import {NoBottomMargin} from "../components/Utils"
import {AuthContext} from "../context/auth";
import {NOTIFICATORAPI} from "../constants";
import {MENUITEMS} from "../StaticMenus";

class Cleanup extends Component {
    static contextType = AuthContext

    constructor(props){
        super(props)
        this.state={
            cleanupSettings: {},
            zabbixTtl: 36500,
            twdTtl: 36500,
            opsgenieTtl: 36500,
        }
        this.postCleanupSettings = this.postCleanupSettings.bind(this)
        this.keyPressed = this.keyPressed.bind(this)
    }

    componentDidMount() {
        axios.get(
            NOTIFICATORAPI + "cleanup",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({ cleanupSettings: response.data })).catch(e => {
            console.log(e)
        })
    }

    postCleanupSettings() {
        axios.post(
            NOTIFICATORAPI + "cleanup",
            {
                zabbix_ttl: this.state.zabbixTtl,
                twd_ttl: this.state.twdTtl,
                opsgenie_ttl: this.state.opsgenieTtl,
            },
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                axios.get(
                    NOTIFICATORAPI + "cleanup",
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).then(response => {
                        this.setState({cleanupSettings: response.data})
                        this.setState({zabbixTtl: response.data.zabbix_ttl})
                        this.setState({twdTtl: response.data.twd_ttl})
                        this.setState({opsgenieTtl: response.data.opsgenie_ttl})
                    }
                )
            }
        }).catch(e => {
            console.log(e)
        })
    }

    keyPressed(event) {
        if (event.key === "Enter") {
            this.postTwdSettings()
        }
    }

    render(){
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                <Container className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <Form>
                                <div className="form-group">
                                    <label>Zabbix events TTL:</label>
                                    <input
                                        type="number"
                                        min="1" max="36500" step="1"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ zabbixTtl: e.target.value})
                                        }}
                                        value={this.state.zabbixTtl}
                                    />
                                    <label>TWD events TTL:</label>
                                    <input
                                        type="number"
                                        min="1" max="36500" step="1"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ twdTtl: e.target.value})
                                        }}
                                        value={this.state.twdTtl}
                                    />
                                    <label>OpsGenie events TTL:</label>
                                    <input
                                        type="number"
                                        min="1" max="36500" step="1"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ opsgenieTtl: e.target.value})
                                        }}
                                        value={this.state.opsgenieTtl}
                                    />
                                </div>
                                <Button onClick={this.postCleanupSettings} className="btn btn-primary">Change</Button>
                            </Form>
                            <hr className="d-sm-none" />
                        </div>
                        <div className="col-sm-8">
                            <NoBottomMargin className="jumbotron text-center">
                                <h2>Settings</h2>
                                <table id="listing" className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Cleanup entity setting</th>
                                        <th>Days</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>Zabbix TTL</td><td>{this.state.cleanupSettings.zabbix_ttl}</td></tr>
                                        <tr><td>TWD TTL</td><td>{this.state.cleanupSettings.twd_ttl}</td></tr>
                                        <tr><td>OpsGenie TTL</td><td>{this.state.cleanupSettings.opsgenie_ttl}</td></tr>
                                    </tbody>
                                </table>
                            </NoBottomMargin>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default Cleanup
