import React from 'react'
import {Link} from "react-router-dom";

const AnalyticsMenu = ({ menu }) => {
    return (
        <div className="col-sm-4">
            <h3>Analytics functions</h3>
                <ul className="nav nav-pills flex-column">
                    {menu.map((menuItem) => (
                        <li className="nav-item"key={menuItem.path}>
                            <Link className="nav-link" exact="true" activeclassname='nav-link active' to={menuItem.path} key={menuItem.path}>{menuItem.name}</Link>
                        </li>
                    ))}
                </ul>
            <hr className="d-sm-none"/>
        </div>
    )
};

export default AnalyticsMenu;
