import React from "react";
import {AuthContext} from "../context/auth";
import Menu from "./Menu";
import AnalyticsMenu from "../components/AnalyticsMenu";
import {Form, Input} from "../components/Forms";
import axios from "axios";
import {NOTIFICATORAPI} from "../constants";
import {ANALYTICSMENUITEMS, MENUITEMS} from "../StaticMenus";
import AnalyticsComponent from "./Analytics/AnalyticsComponent";

class ClickupWeekOverview extends AnalyticsComponent {
    static contextType = AuthContext
    constructor(props){
        super(props)
        this.state={
            slackList: [],
            slackChannelId: "",
            cronline: ""
        }
        this.postScheduleExport = this.postScheduleExport.bind(this)
        this.deleteScheduleExport = this.deleteScheduleExport.bind(this)
        this.getScheduleOverview = this.getScheduleOverview.bind(this)
    }

    componentDidMount() {
        this.getSlackChannels()
        this.getScheduleOverview()
    }

    getScheduleOverview(){
        this.setState({slackChannelId: "", cronline: ""})
        axios.get(
            NOTIFICATORAPI + "analytics/clickup_week_overview",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({
            slackChannelId: response.data.channel_id,
            cronline: response.data.cronline,
        })).catch(e => {
            console.log(e)
        })
    }

    deleteScheduleExport(){
        if (this.state.slackChannelId !== ""){
            axios.delete(
                NOTIFICATORAPI + "analytics/clickup_week_overview",
                {
                    headers: {
                        'x-user-token': this.context.authTokens
                    }
                }
            ).catch(e => {
                console.log(e)
            })
        }
        this.getScheduleOverview()
    }

    postScheduleExport(){
        this.deleteScheduleExport()
        axios.post(
            NOTIFICATORAPI + "analytics/clickup_week_overview",
            {
                channel_id: this.state.slackChannelId,
                cronline: this.state.cronline,
            },
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                this.getScheduleOverview()
                this.setState({ errorMessage: "" })
            }
        }).catch(e => {
            console.log(e)
            this.setState({ errorMessage: "Settings failed to apply, " + e.response.data.status })
        })        
    }

    render() {
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                {this.state.errorMessage &&
                <div className="container">
                    <div className="alert alert-danger">
                        <strong>Error!</strong> {this.state.errorMessage}
                    </div>
                </div>
                }
                <div className="container">
                    <div className="row">
                        <AnalyticsMenu menu={ANALYTICSMENUITEMS} />
                        <div className="col-sm-8">
                            <h2>ClickUp Week Overview</h2>
                            <p>This menu here serves for week overview of time spent user overview. It will report summaries of time spent on customer and by each user.</p>
                            <p>Whole report is pushed to Slack channel.</p>
                        </div>
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-4">
                            <Form>
                                <div className="form-group">
                                    <label>Cronline:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ cronline: e.target.value})
                                        }}
                                        value={this.state.cronline}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Slack Channel Name:</label>
                                    <select
                                        className="form-control"
                                        onChange={e => {
                                            this.setState({ slackChannelId: e.target.value})
                                        }}
                                        value={this.state.slackChannelId}
                                    >
                                        {this.state.slackList.concat([{slack_channel_id: "", slack_channel_name: ""}]).map((item) =>
                                            <option key={item.slack_channel_id} value={item.slack_channel_id} id={item.slack_channel_id}>{item.slack_channel_name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <span> </span>
                                    <button onClick={this.postScheduleExport} className="btn btn-primary">Add</button>
                                    <span> </span>
                                    <button onClick={this.deleteScheduleExport} className="btn">Disable</button>
                                </div>
                            </Form>
                            <hr className="d-sm-none" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ClickupWeekOverview