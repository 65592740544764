import React from "react";
import {AuthContext} from "../context/auth";
import Menu from "./Menu";
import AnalyticsMenu from "../components/AnalyticsMenu";
import {Form, Input} from "../components/Forms";
import axios from "axios";
import {NOTIFICATORAPI} from "../constants";
import {ANALYTICSMENUITEMS, MENUITEMS} from "../StaticMenus";
import AnalyticsComponent from "./Analytics/AnalyticsComponent";

class ClickupMonthExport extends AnalyticsComponent {
    static contextType = AuthContext
    constructor(props){
        super(props)
        this.state={
            cronline: "",
            notificatorServiceAccountEmail: "",
            gdriveUrl: "",
            schedules: [],
        }

        this.postScheduleExport = this.postScheduleExport.bind(this)
        this.deleteScheduleExport = this.deleteScheduleExport.bind(this)
        this.getScheduleExport = this.getScheduleExport.bind(this)
    }

    componentDidMount() {
        axios.get(
            NOTIFICATORAPI + "sa_detail",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({
            notificatorServiceAccountEmail: response.data.sa_email,
        }))
        this.getScheduleExport()
    }

    getScheduleExport(){
        this.setState({cronline: "", gdriveUrl: ""})
        axios.get(
            NOTIFICATORAPI + "schedule/setting",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then((response) => {
                this.setState(
                    { 
                        schedules: response.data.filter(function (el) {
                                return el.action_type === "clickup_project_month_export"
                            }
                        )
                    }
                )
                if (this.state.schedules.length > 0){
                    this.setState(
                        {
                            cronline: this.state.schedules[0].cronline,
                            gdriveUrl: JSON.parse(this.state.schedules[0].summary).google_drive_folder
                        }
                    )
                }
            }
        )
    }

    deleteScheduleExport(alsoFetchSchedule = true){
        this.state.schedules.forEach((value) => {
                axios.delete(
                    NOTIFICATORAPI + "schedule/setting/" + value.id,
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).catch(e => {
                    console.log(e)
                })
            }
        )
        if (alsoFetchSchedule)
            this.getScheduleExport()
    }

    postScheduleExport(){
        this.deleteScheduleExport(false)
        let fail_flag = false
        if (this.state.gdriveUrl === "") {
            this.setState({ errorMessage: "Google drive folder URL is missing!"})
            fail_flag = true
        }
        if (this.state.cronline === "") {
            this.setState({ errorMessage: "Cronline is not set!"})
            fail_flag = true
        }
        if (!fail_flag)
            axios.post(
                NOTIFICATORAPI + "gdrive/test_folder",
                {
                    url: this.state.gdriveUrl,
                },
                {
                    headers: {
                        'x-user-token': this.context.authTokens
                    }
                }
            ).then(result => {
                if (result.status === 200) {
                    console.log(result.data)
                    this.setState({ errorMessage: "" })
                    axios.post(
                        NOTIFICATORAPI + "schedule/setting",
                        {
                            summary: JSON.stringify({google_drive_folder: this.state.gdriveUrl}),
                            cronline: this.state.cronline,
                            action_type: "clickup_project_month_export"
                        },
                        {
                            headers: {
                                'x-user-token': this.context.authTokens
                            }
                        }
                    ).catch(e => {
                        console.log(e)
                        this.setState({errorMessage: "Format of the schedule posted is wrong, see cronline"})
                    })
                }
            }).catch(e => {
                console.log(e)
                this.setState({ errorMessage: "Settings failed to apply, " + e.response.data.status })
            })        
    }

    render() {
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                {this.state.errorMessage &&
                <div className="container">
                    <div className="alert alert-danger">
                        <strong>Error!</strong> {this.state.errorMessage}
                    </div>
                </div>
                }
                <div className="container">
                    <div className="row">
                        <AnalyticsMenu menu={ANALYTICSMENUITEMS} />
                        <div className="col-sm-8">
                            <h2>ClickUp Month Export</h2>
                            <p>This menu here serves for monthly exports of timesheet from ClickUp</p>
                            <p>Do not forget to add notificator service account email <b>{this.state.notificatorServiceAccountEmail}</b> as editor of the folder for exports.</p>
                        </div>
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-4">
                            <Form>
                                <div className="form-group">
                                    <label>Cronline:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ cronline: e.target.value})
                                        }}
                                        value={this.state.cronline}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Google cloud folder URL:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ gdriveUrl: e.target.value})
                                        }}
                                        value={this.state.gdriveUrl}
                                    />
                                </div>
                                <div className="form-group">
                                    <span> </span>
                                    <button onClick={this.postScheduleExport} className="btn btn-primary">Add</button>
                                    <span> </span>
                                    <button onClick={this.deleteScheduleExport} className="btn">Disable</button>
                                </div>
                            </Form>
                            <hr className="d-sm-none" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ClickupMonthExport