import React, {Component} from "react"
import Menu from "./Menu"
import axios from 'axios'
import { Container } from "../components/Main"
import {Button, Form, Input} from "../components/Forms"
import {NoBottomMargin} from "../components/Utils"
import {Link} from "react-router-dom"
import {AuthContext} from "../context/auth";
import {NOTIFICATORAPI} from "../constants";
import {MENUITEMS} from "../StaticMenus";

class TwdSettings extends Component {
    static contextType = AuthContext

    constructor(props){
        super(props)
        this.state={
            twdItems: [],
            key: "",
            endpoint: "",
        }
        this.postTwdSettings = this.postTwdSettings.bind(this)
        this.deleteTwdSetting = this.deleteTwdSetting.bind(this)
        this.keyPressed = this.keyPressed.bind(this)
    }

    componentDidMount() {
        axios.get(
            NOTIFICATORAPI + "twd/setting",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({ twdItems: response.data }))
    }

    deleteTwdSetting(itemId){
        axios.delete(
            NOTIFICATORAPI + "twd/setting/" + itemId,
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                axios.get(
                    NOTIFICATORAPI + "twd/setting",
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).then(response => this.setState({ twdItems: response.data }))
            }
        }).catch(e => {
            console.log(e)
        })
    }

    postTwdSettings() {
        axios.post(
            NOTIFICATORAPI + "twd/setting",
            {
                endpoint: this.state.endpoint,
                key: this.state.key,
            },
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                axios.get(
                    NOTIFICATORAPI + "twd/setting",
                    {
                        headers: {
                            'x-user-token': this.context.authTokens
                        }
                    }
                ).then(response => this.setState({ twdItems: response.data }))
            }
        }).catch(e => {
            console.log(e)
        })
    }

    keyPressed(event) {
        if (event.key === "Enter") {
            this.postTwdSettings()
        }
    }

    render(){
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                <Container className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <Form>
                                <div className="form-group">
                                    <label>Endpoint:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ endpoint: e.target.value})
                                        }}
                                        value={this.state.endpoint}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Key:</label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ key: e.target.value})
                                        }}
                                        value={this.state.key}
                                    />
                                </div>
                                <Button onClick={this.postTwdSettings} className="btn btn-primary">Add</Button>
                            </Form>
                            <hr className="d-sm-none" />
                        </div>
                        <div className="col-sm-8">
                            <NoBottomMargin className="jumbotron text-center">
                                <h2>Listing</h2>
                                <table id="listing" className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Endpoint</th>
                                        <th>Key</th>
                                        <th> </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.twdItems.map((item) => (
                                        <tr key={item.endpoint}><td>{item.endpoint}</td><td>{item.key}</td><td><Link to='/twd' onClick={() => this.deleteTwdSetting(item.id)}>delete</Link></td></tr>
                                    ))}
                                    </tbody>
                                </table>
                            </NoBottomMargin>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default TwdSettings
