import React, {Component} from "react";
import {AuthContext} from "../context/auth";
import Menu from "./Menu";
import AnalyticsMenu from "../components/AnalyticsMenu";
import {Form, Input} from "../components/Forms";
import axios from "axios";
import {NOTIFICATORAPI} from "../constants";
import {ANALYTICSMENUITEMS, MENUITEMS} from "../StaticMenus";

class ShortTicketLookback extends Component {
    static contextType = AuthContext
    constructor(props){
        super(props)
        this.state={
            slackList: [],
            slackChannelId: "",
            cronline: "",
            period: 7,
            similarity: 0.5
        }

        this.postTicketLookback = this.postTicketLookback.bind(this)
        this.getTicketLookback = this.getTicketLookback.bind(this)
        this.getSlackChannels = this.getSlackChannels.bind(this)
        this.deleteTicketLookback = this.deleteTicketLookback.bind(this)
    }

    componentDidMount() {
        this.getSlackChannels()
        this.getTicketLookback()
    }

    getTicketLookback(){
        axios.get(
            NOTIFICATORAPI + "analytics/ticket_period_summary",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({
            slackChannelId: response.data.channel_id,
            cronline: response.data.cronline,
            period: response.data.period,
            similarity: response.data.similarity
        }))
    }

    postTicketLookback() {
        axios.post(
            NOTIFICATORAPI + "analytics/ticket_period_summary",
            {
                channel_id: this.state.slackChannelId,
                cronline: this.state.cronline,
                period: this.state.period,
                similarity: this.state.similarity
            },
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                this.getTicketLookback()
                this.setState({ errorMessage: "" })
            }
        }).catch(e => {
            console.log(e)
            this.setState({ errorMessage: "Settings failed to apply, " + e.response.data.status })
        })
    }

    deleteTicketLookback(){
        axios.delete(
            NOTIFICATORAPI + "analytics/ticket_period_summary",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            this.setState({
                slackChannelId: "",
                cronline: "",
                period: 7,
                similarity: 0.5
            })
            this.getSlackChannels()
            this.setState({ errorMessage: "" })
        }).catch(e => {
            console.log(e)
        })
    }

    getSlackChannels(){
        axios.get(
            NOTIFICATORAPI + "slack/list",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({ slackList: response.data }))
    }

    render() {
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                {this.state.errorMessage &&
                <div className="container">
                    <div className="alert alert-danger">
                        <strong>Error!</strong> {this.state.errorMessage}
                    </div>
                </div>
                }
                <div className="container">
                    <div className="row">
                        <AnalyticsMenu menu={ANALYTICSMENUITEMS} />
                        <div className="col-sm-8">
                            <h2>Short Ticket Lookback</h2>
                            <p>This is a cronjob which sends a summary to defined channel about the tickets which repeats itself in a past day. For example: display all tickets to channel #12345 which repeats itself in past week. It allows you to define the level of similarity. Tickets could be different in few words, by setting the level to lower setting, the tickets would be considered the same.</p>
                        </div>
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-4">
                            <Form>
                                <div className="form-group">
                                    <label>Lookback period:</label>
                                    <input
                                        type="number"
                                        min="1" max="365" step="1"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ period: e.target.value})
                                        }}
                                        value={this.state.period}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Cronline:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ cronline: e.target.value})
                                        }}
                                        value={this.state.cronline}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Slack Channel Name:</label>
                                    <select
                                        className="form-control"
                                        onChange={e => {
                                            this.setState({ slackChannelId: e.target.value})
                                        }}
                                        value={this.state.slackChannelId}
                                    >
                                        {this.state.slackList.map((item) =>
                                            <option key={item.slack_channel_id} value={item.slack_channel_id} id={item.slack_channel_id}>{item.slack_channel_name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Text similarity:</label>
                                    <input
                                        type="range"
                                        min="0" max="1" step="0.01"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ similarity: e.target.value})
                                        }}
                                        value={this.state.similarity}
                                    />
                                </div>
                                <div className="form-group">
                                    <button onClick={this.postTicketLookback} className="btn btn-primary">Add</button>
                                    <span> </span>
                                    <button onClick={this.deleteTicketLookback} className="btn">Disable</button>
                                </div>
                            </Form>
                            <hr className="d-sm-none" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShortTicketLookback