import React from "react";
import MenuItems from '../components/MenuItems';
import { MENUITEMS } from '../StaticMenus';

function Menu(props) {
    return (
        <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
            <a className="navbar-brand" href="/">HOME</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <MenuItems menu={MENUITEMS} />
            </div>
        </nav>
    );
}

export default Menu;
