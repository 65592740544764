import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import { Card, Form, Input, Button, Error } from "../components/Forms";
import { useAuth } from "../context/auth";
import { NOTIFICATORAPI } from '../constants';

function Login(props) {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [userToken, setUserToken] = useState("");
    const { setAuthTokens } = useAuth();
    const referer = props.location.state.referer || '/';

    function postLogin() {
        axios.post(NOTIFICATORAPI + "login",
            {}, { headers: { 'x-user-token': userToken } }
        ).then(result => {
            console.log(result.data);
            if (result.status === 200) {
                setAuthTokens(result.data);
                setLoggedIn(true);
            } else {
                setIsError(true);
            }
        }).catch(e => {
            console.log(e);
            setIsError(true);
        });
    }
    
    function keyPressed(event) {
        if (event.key === "Enter") {
            postLogin();
        }
    }
    
    if (isLoggedIn) {
        return <Redirect to={referer} />;
    }

    return (
        <Card>
            <Form>
                <Input
                    type="password"
                    value={userToken}
                    onKeyPress={keyPressed}
                    onChange={e => {
                        setUserToken(e.target.value);
                    }}
                    placeholder="password"
                />
                <Button onClick={postLogin} type="submit">Sign In</Button>
            </Form>
            { isError &&<Error>The username or password provided were incorrect!</Error> }
        </Card>
    );
}

export default Login;
