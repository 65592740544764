import React, {Component} from "react"
import Menu from "./Menu"
import axios from 'axios'
import { Container } from "../components/Main"
import {Button, Form, Input} from "../components/Forms"
import {NoBottomMargin} from "../components/Utils"
import {Link} from "react-router-dom"
import {AuthContext} from "../context/auth";
import {NOTIFICATORAPI} from "../constants";
import {MENUITEMS} from "../StaticMenus";

class Policies extends Component {
    static contextType = AuthContext

    constructor(props){
        super(props)
        this.state={
            policies: [],
            policy: "",
        }
        this.postPolicy = this.postPolicy.bind(this)
        this.getPolicies = this.getPolicies.bind(this)
        this.deletePolicy = this.deletePolicy.bind(this)
        this.keyPressed = this.keyPressed.bind(this)
    }

    getPolicies(){
        axios.get(
            NOTIFICATORAPI + "slack/policy",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({ policies: response.data }))
    }

    componentDidMount() {
        this.getPolicies()
    }

    deletePolicy(itemId){
        axios.delete(
            NOTIFICATORAPI + "slack/policy/" + itemId,
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                this.getPolicies()
            }
        }).catch(e => {
            console.log(e)
        })
    }

    postPolicy() {
        axios.post(
            NOTIFICATORAPI + "slack/policy",
            {
                policy: this.state.policy,
            },
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                this.getPolicies()
            }
        }).catch(e => {
            console.log(e)
        })
    }

    keyPressed(event) {
        if (event.key === "Enter") {
            this.postTwdSettings()
        }
    }

    render(){
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                <Container className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <Form>
                                <div className="form-group">
                                    <label>Policy:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ policy: e.target.value})
                                        }}
                                        value={this.state.policy}
                                    />
                                </div>
                                <Button onClick={this.postPolicy} className="btn btn-primary">Add</Button>
                            </Form>
                            <hr className="d-sm-none" />
                            <p>Here are some implemented policies you can use, if you put there rubbish, you'll
                                be punished!</p>
                            <ul className="nav nav-pills flex-column">
                                <li className="nav-item">
                                    <pre>ack message min length 10</pre>
                                </li>
                                <li className="nav-item">
                                    <pre>clear message allow user lukaspour</pre>
                                </li>
                            </ul>
                            <hr className="d-sm-none" />
                        </div>
                        <div className="col-sm-8">
                            <NoBottomMargin className="jumbotron text-center">
                                <h2>Listing</h2>
                                <table id="listing" className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Policy</th>
                                        <th> </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.policies.map((item) => (
                                        <tr key={item.id}><td>{item.policy}</td><td><Link to='/policy' onClick={() => this.deletePolicy(item.id)}>delete</Link></td></tr>
                                    ))}
                                    </tbody>
                                </table>
                            </NoBottomMargin>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default Policies
