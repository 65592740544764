import React from "react";
import {AuthContext} from "../context/auth";
import Menu from "./Menu";
import AnalyticsMenu from "../components/AnalyticsMenu";
import {Form, Input} from "../components/Forms";
import axios from "axios";
import {NOTIFICATORAPI} from "../constants";
import {ANALYTICSMENUITEMS, MENUITEMS} from "../StaticMenus";
import AnalyticsComponent from "./Analytics/AnalyticsComponent";

class IngestedEvents extends AnalyticsComponent {
    static contextType = AuthContext
    constructor(props){
        super(props)
        this.state={
            slackList: [],
            slackChannelId: "",
            cronline: "",
            period: 300
        }

        this.postAlertLookback = this.postAlertLookback.bind(this)
        this.getAlertLookback = this.getAlertLookback.bind(this)
        this.getSlackChannels = this.getSlackChannels.bind(this)
        this.deleteAlertLookback = this.deleteAlertLookback.bind(this)
    }

    componentDidMount() {
        this.getSlackChannels()
        this.getAlertLookback()
    }

    getAlertLookback(){
        axios.get(
            NOTIFICATORAPI + "analytics/ingested_events",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(response => this.setState({
            slackChannelId: response.data.channel_id,
            cronline: response.data.cronline,
            period: response.data.period
        })).catch(e => {
            console.log(e)
        })
    }

    postAlertLookback() {
        axios.post(
            NOTIFICATORAPI + "analytics/ingested_events",
            {
                channel_id: this.state.slackChannelId,
                cronline: this.state.cronline,
                period: this.state.period
            },
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            if (result.status === 200) {
                console.log(result.data)
                this.getAlertLookback()
                this.setState({ errorMessage: "" })
            }
        }).catch(e => {
            console.log(e)
            this.setState({ errorMessage: "Settings failed to apply, " + e.response.data.status })
        })
    }

    deleteAlertLookback(){
        axios.delete(
            NOTIFICATORAPI + "analytics/ingested_events",
            {
                headers: {
                    'x-user-token': this.context.authTokens
                }
            }
        ).then(result => {
            this.setState({
                slackChannelId: "",
                cronline: "",
                period: 300
            })
            this.getSlackChannels()
            this.setState({ errorMessage: "" })
        }).catch(e => {
            console.log(e)
        })
    }

    render() {
        return (
            <div>
                <Menu menu={MENUITEMS}/>
                {this.state.errorMessage &&
                <div className="container">
                    <div className="alert alert-danger">
                        <strong>Error!</strong> {this.state.errorMessage}
                    </div>
                </div>
                }
                <div className="container">
                    <div className="row">
                        <AnalyticsMenu menu={ANALYTICSMENUITEMS} />
                        <div className="col-sm-8">
                            <h2>Short Alert Lookback</h2>
                            <p>This should display statistics about the ingested events which got posted by Notificator into the Slack channels.</p>
                        </div>
                        <div className="col-sm-4">
                        </div>
                        <div className="col-sm-4">
                            <Form>
                                <div className="form-group">
                                    <label>Cronline:</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onKeyPress={this.keyPressed}
                                        onChange={e => {
                                            this.setState({ cronline: e.target.value})
                                        }}
                                        value={this.state.cronline}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Slack Channel Name:</label>
                                    <select
                                        className="form-control"
                                        onChange={e => {
                                            this.setState({ slackChannelId: e.target.value})
                                        }}
                                        value={this.state.slackChannelId}
                                    >
                                        {this.state.slackList.concat([{slack_channel_id: "", slack_channel_name: ""}]).map((item) =>
                                            <option key={item.slack_channel_id} value={item.slack_channel_id} id={item.slack_channel_id}>{item.slack_channel_name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <button onClick={this.postAlertLookback} className="btn btn-primary">Add</button>
                                    <span> </span>
                                    <button onClick={this.deleteAlertLookback} className="btn">Disable</button>
                                </div>
                            </Form>
                            <hr className="d-sm-none" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default IngestedEvents