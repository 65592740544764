export const MENUITEMS = [
    {
        name: "Zabbix settings",
        path: "/zabbix"
    },
    {
        name: "TWD settings",
        path: "/twd"
    },
    {
        name: "OpsGenie settings",
        path: "/opsgenie"
    },
    {
        name: "ClickUp settings",
        path: "/clickup"
    },
    {
        name: "Schedules",
        path: "/schedule"
    },
    {
        name: "Slack policies",
        path: "/policy"
    },
    {
        name: "Channel mappings",
        path: "/mappings"
    },
    {
        name: "Analytics",
        path: "/analytics"
    },
    {
        name: "Cleanup settings",
        path: "/cleanup"
    }
];

export const ANALYTICSMENUITEMS = [
    {
        name: "Short alert look back",
        path: "/analytics/alert_period_summary"
    },
    {
        name: "Short ticket look back",
        path: "/analytics/ticket_period_summary"
    },
    {
        name: "Ingested events",
        path: "/analytics/ingested_events"
    },
    {
        name: "ClickUp Month Export",
        path: "/analytics/clickup_month_export"
    },
    {
        name: "ClickUp Week Overview",
        path: "/analytics/clickup_week_overview"
    },
];
